.mobileWebEntryContainer {
  position: relative;
  height: 100vh;
  background-color: var(--secondary-color-5);
  background-image: url('/assets/img/mobileOverlay/background-ink-textured.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.logoContainer {
  display: block;
  padding-top: 15px;
  max-width: 312px;
  margin: auto;
  color: #fff;

  @media (--min-width-breakpoint-360) {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  @media (--min-width-xsmall) {
    padding: 30px 0;
  }

  @media (--min-width-medium) and (orientation: portrait) {
    padding-top: 100px;
  }

  @media only screen and (--max-width-large) and (orientation: landscape) {
    display: none;
  }
}

.logoTitle {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  line-height: 20px;
}

.valuePropContainer {
  display: none;

  @media (--min-width-xxsmall) {
    /* Positioned with z-index so that it sits on top of the */

    /* background's gradient container so that the color */

    /* gradient doesn't bleed through to the font color. */
    position: relative;
    z-index: 3;
    padding-top: 15px;
    margin: auto;
    max-width: 312px;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    line-height: 32px;
    font-family: var(--primary-font-header-bold);
    display: block;
  }

  @media (--min-width-breakpoint-360) {
    padding-top: 20px;
  }

  @media (--min-width-xsmall) {
    padding-top: 30px;
  }

  @media (--min-width-medium) {
    padding-top: 50px;
  }

  @media only screen and (--max-width-large) and (orientation: landscape) {
    display: none;
  }
}

.bottomSection {
  position: absolute;
  width: 100%;
  bottom: 175px;
  z-index: 3;

  @media screen and (--max-width-xsmall) and (--min-height-breakpoint-812) {
    bottom: 225px;
  }
}

.buttonContainer {
  margin: 0 auto;
  max-width: 312px;
}

.buttonContainerNoGuideId {
  margin-top: 125px;
  max-width: 292px;
}

.infoContainer {
  border-radius: 2px;
  background-color: #fff;
  border: solid 0.5px #eaeaea;
  padding: 24px;
  position: relative;
}

.fullWidthInfoHeader {
  width: 100%;
}

.infoHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.imageContainer {
  margin-right: 12px;
}

.image {
  width: 88px;
  height: 88px;
  border-radius: 2px;
  border: solid 0.5px var(--border-light-color);
}

.imageWithPlayButton {
  width: 64px;
  height: 64px;
}

.titleWithImage {
  width: 100%;
  font-size: 22px;
  line-height: 26px;
  font-weight: var(--font-weight-bold);
  color: var(--secondary-color-5);
}

.titleWithPlayButton {
  font-size: 21px;
}

.titleWithoutImage {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 2px;
  color: var(--secondary-color-5);
}

.subtitle {
  composes: truncate from '../../common/styles/common.module.scss';
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  color: var(--secondary-color-5);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.center {
    flex-grow: 1;
    text-align: center;
  }

  &.withPlayButton {
    width: 125px;
  }
}

.playButton {
  position: absolute;
  top: 34px;
  right: 24px;
}

.secondaryInfoContainer {
  margin-top: 13px;
  line-height: 19px;
  color: var(--secondary-color-5);
}

.downloadLink {
  composes: link from '../styles/common.module.scss';
}

.downloadLinkButton {
  display: block;
  width: 100%;
  margin: auto;
  margin-top: 12px;
  border: none !important;
  height: 48px;

  span {
    color: white !important;
    font-size: var(--font-size-5);
    font-weight: 600;
    letter-spacing: var(--letter-spacing-loose);

    &:hover,
    &:active,
    &:focus {
      color: white !important;
    }
  }
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  font-size: var(--font-size-3);
  cursor: pointer;
  color: #fff;
}

/* NEW UI CSS */
.imageContainerNew {
  text-align: center;
}

.imageNew {
  width: 150px;
  height: 150px;
  border-radius: 2px;
  border: solid 0.2px var(--border-light-color);
  margin: 20px;

  @media only screen and (--min-width-breakpoint-360) and (orientation: portrait) {
    width: 200px;
    height: 200px;
  }

  @media only screen and (--min-width-xsmall) and (orientation: portrait) {
    width: 200px;
    height: 200px;
    margin: 35px;
  }

  @media only screen and (--min-width-small) and (orientation: portrait) {
    width: 300px;
    height: 300px;
    margin: 50px;
  }

  @media only screen and (--min-width-large) and (orientation: portrait) {
    width: 400px;
    height: 400px;
    margin: 50px;
  }
}

.bottomSectionNew {
  width: 315px;
  z-index: 3;
  border-radius: 2px;
  background-color: #fff;
  text-align: center;
  padding: 20px;
  margin: 0 auto;

  @media (--min-width-xsmall) {
    width: 400px;
  }

  @media (--min-width-small) {
    width: 450px;
  }

  @media (--min-width-medium) {
    width: 500px;
  }

  @media (--min-width-large) {
    width: 550px;
  }
}

.titleNew {
  composes: truncate from '../../common/styles/common.module.scss';
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  font-weight: var(--font-weight-bold);
  color: var(--secondary-color-5);
}

.valuePropNew {
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  color: var(--secondary-color-5);
}
